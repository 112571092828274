<template>
  <div ref="gallery-hero-wrapper" class="gallery-hero-wrapper">
    <div class="gallery-main">
      <div v-if="renderProgress" class="progress-wrapper">
        <b-progress>
          <b-progress-bar
            :value="progressValue"
            :max="progressMax"
            variant="warning"
            show-progress
            animated
          >
            {{ progressValue }} / {{ progressMax }}
          </b-progress-bar>
        </b-progress>
      </div>
      <VueSlickCarousel
        ref="c1"
        :asNavFor="c2Ref"
        :focusOnSelect="true"
      >
        <img
          v-for="(imageSrc, imageSrcIndex) in images"
          :key="imageSrcIndex"
          :class="`gallery-hero-image ${imageSrcIndex}`"
          :src="imageSrc"
          alt="Responsive image"/>
        <template #prevArrow>
          <div class="custom-arrow">
            <div class="chevron">
              <font-awesome-icon :icon="['far', 'chevron-left']"/>
            </div>
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-arrow">
            <div class="chevron">
              <font-awesome-icon :icon="['far', 'chevron-right']"/>
            </div>
          </div>
        </template>
      </VueSlickCarousel>
    </div>
    <div class="gallery-thumbs">
      <VueSlickCarousel
        :arrows="false"
        ref="c2"
        :asNavFor="c1Ref"
        :slidesToShow="4"
        :focusOnSelect="true"
      >
        <img
          v-for="(imageSrc, imageSrcIndex) in images"
          :key="imageSrcIndex"
          class="gallery-thumb"
          :src="imageSrc"
          alt="Responsive image"/>
      </VueSlickCarousel>
  </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'Gallery',
  props: {
    images: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      c1Ref: null,
      c2Ref: null,
      progressValue: 0,
      progressMax: 0,
      renderProgress: true,
    }
  },

  components: {
    VueSlickCarousel,
  },

  mounted() {
    // hopefully a better solution will come around
    // https://stackoverflow.com/questions/58339362/asnavfor-not-working-ngx-slick-carousel-in-angular-8
    this.c1Ref = this.$refs.c1;
    this.c2Ref = this.$refs.c2;

    this.$nextTick(() => {
      this.setImagePosition();
    });
  },

  methods: {
    setImagePosition() {
      const galleryPhotos = document.getElementsByClassName('gallery-hero-image');
      this.progressMax = galleryPhotos.length;

      galleryPhotos.forEach((photo) => {
        if (photo.naturalHeight > photo.naturalWidth) {
          photo.classList.add('portrait');
        }

        // If both the width and height are 0, it probably means the image has not loaded yet
        if (photo.naturalHeight === 0 && photo.naturalWidth === 0) {
          setTimeout(() => {
            if (photo.naturalHeight > photo.naturalWidth) {
              photo.classList.add('portrait');
            }
          }, 1000);
        }

        this.progressValue++;

        if (this.progressValue === this.progressMax) {
          this.renderProgress = false;
        }
      });
    },
  },
}
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.8;
}

#modal-gallery {
  @include media-breakpoint-up(lg) {
    > .modal-xl {
      max-width: 1500px !important;
    }
  }

  > .modal-xl {
    max-width: 800px;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }

  .modal-body {
    padding: 1rem 0;
  }

  .custom-arrow {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &.slick-next {
      right: -5px;
      @include media-breakpoint-up(sm) {
        right: 25px;
      }
    }

    &.slick-prev {
      left: -5px;
      @include media-breakpoint-up(sm) {
        left: 25px;
      }
    }

    &.slick-disabled {
      display: none;
    }

    svg {
      width: 25px;
      height: 25px;
      color: #fff;
    }

    &::before {
      content: none;
    }
  }

  .gallery-hero-wrapper {
    .gallery-main {
      $imageHeight: 640px;

      @media (max-width: 1200px) {
        $imageHeight: 70vh;
      }

      position: relative;
      height: $imageHeight;

      .progress-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 2;

        > .progress {
          width: 100%;
        }
      }

      .slick-slide {
        max-height: $imageHeight;

        img {
          display: block !important;
          margin-inline: auto;
          border-radius: 3px;
          border: 3px solid #fff;
          width:unset !important;
          max-width: 85vw;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }
  }

  .gallery-thumbs {
    position: relative;
    width: 50%;
    margin: 0 auto 0;

    @include media-breakpoint-up(sm) {
      margin: 26px auto 0;
    }

    @media (max-width: 1200px) {
      width: 100%;
    }

    .slick-slide {
      padding: 3px;
      // width: initial !important;

      &.slick-current {
        img {
          border: 2px solid $primary;
        }
      }

      img {
        $thumbnailWidth: 168px;

        border-radius: 3px;
        margin: 10px;
        border: 2px solid #fff;
        overflow: hidden;
        height: ($thumbnailWidth/16) * 9;
        width: $thumbnailWidth;
        -o-object-fit: cover;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}
</style>
