<template>
  <div class="detail-hero-wrapper"
       ref="detailHeroWrapper"
       :style="heroBackground"
  >
    <div class="hero-header mb-auto">
      <b-button
        class="round-icon-button"
        pill
        variant="outline-primary"
        @click="gotToListings"
      >
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"/>
      </b-button>
      <div class="name-small">
        <h3>{{ smallName }}</h3>
      </div>
    </div>
    <div class="hero-body">
      <GardenGroupInfo :garden="garden"/>
    </div>
    <div class="hero-footer">
      <div class="footer-name">
        <div class="garden-name">
          <h1 class="m-0">{{ garden.name }}</h1>
        </div>
        <div class="garden-location">
          <h4 class="m-0">{{ gardenLocation }}</h4>
        </div>
      </div>
      <div class="footer-buttons">
        <b-button
          class="ngs-font"
          v-if="gmapsAddress"
          :href="gmapsAddress"
          variant="light"
          target="_blank"
          size="lg"
        >
          Directions
        </b-button>
        <b-button
          class="ngs-font button-no-focus"
          variant="primary"
          size="lg"
          :disabled="!gardenPhotos.length"
          v-b-modal.modal-gallery
        >
          Gallery
        </b-button>
        <b-modal
          id="modal-gallery"
          :dialog-class="[isIos ? 'gallery-modal-ios' : '']"
          size="xl"
          centered
          hide-footer
          @show="toggleStatusBar"
          @hide="toggleStatusBar"
        >
          <gallery :images="gardenPhotos"/>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { clone, merge } from 'lodash'
import Gallery from '@/components/gardenDetails/gallery/Gallery'
import GardenGroupInfo from '@/components/gardenDetails/GardenGroupInfo';

export default {
  name: 'DetailHero',

  data() {
    return {
      usingCurrentLocation: false,
      previousSearchedTerm: null,
      previousUserGeoLocation: null,
      previousSelectedLocation: null,
    }
  },

  props: {
    garden: {
      type: Object,
      required: true,
    },
  },

  components: {
    GardenGroupInfo,
    Gallery,
  },

  mounted() {
    if (this.useCurrentLocation) {
      this.usingCurrentLocation = true
      this.previousSearchedTerm = this.searchTerm
      this.previousUserGeoLocation = this.userGeoLocation
      this.previousSelectedLocation = this.selectedLocation
      this.clearUserGeoLocation()
      this.switchOffCurrentLocationOnly()
    }
  },

  computed: {
    ...mapGetters('garden', [
      'searchTerm',
    ]),

    ...mapGetters('filter', [
      'selectedFiltersQuery',
    ]),

    ...mapGetters('map', [
      'searchBoundsQuery',
    ]),

    ...mapGetters('sort', [
      'selectedSortValue',
    ]),

    ...mapGetters('location', [
      'useCurrentLocation',
      'userGeoLocation',
      'selectedLocation',
    ]),

    heroBackground() {
      /* eslint-disable global-require */
      return this.garden.images[0]
        ? `background-image: url('${this.garden.images[0]}')`
        : `background-image: url(${require('@/assets/images/garden-placeholder.png')})`;
      /* eslint-enable global-require */
    },
    smallName() {
      return this.garden.name || '';
    },
    gardenLocation() {
      const gardenLocation = [];

      gardenLocation.push(this.garden.address_2 || null);
      gardenLocation.push(this.garden.address_3 || null);
      gardenLocation.push(this.garden.town || null);
      gardenLocation.push(this.garden.county || null);
      gardenLocation.push(this.garden.postcode || null);

      return gardenLocation.filter((a) => a)
        .join(', ');
    },
    gmapsAddress() {
      const { latitude, longitude } = this.garden;

      if (!latitude || !longitude) {
        return null;
      }

      return `https://www.google.com/maps/place/${latitude},${longitude}`;
    },
    gardenPhotos() {
      return this.garden.images;
    },
    isIos() {
      return Capacitor.getPlatform() === 'ios';
    },
  },

  methods: {
    ...mapActions('garden', [
      'updateSearchTerm',
    ]),

    ...mapActions('location', [
      'clearUserGeoLocation',
      'switchOffCurrentLocationOnly',
      'switchOnCurrentLocationOnly',
    ]),

    ...mapMutations('location', [
      'setUserGeoLocation',
      'setSelectedLocation',
    ]),

    async gotToListings() {
      if (this.usingCurrentLocation) {
        await this.switchOnCurrentLocationOnly()
        await this.setUserGeoLocation(this.previousUserGeoLocation)
        await this.setSelectedLocation(this.previousSelectedLocation)
        await this.updateSearchTerm(this.previousSearchedTerm)
      }
      const filters = this.selectedFiltersQuery
      const searchBounds = this.searchBoundsQuery
      const selectedSort = this.selectedSortValue
      const search = this.searchTerm
      let urlQuery = clone(filters)

      if (search) {
        urlQuery = merge(urlQuery, { search_term: search })
      }
      if (searchBounds) {
        urlQuery = merge(urlQuery, { search_bounds: searchBounds })
      }

      if (selectedSort) {
        urlQuery = merge(urlQuery, { sort_by: selectedSort })
      }

      this.$router.push({ name: 'List', query: urlQuery })
    },

    async toggleStatusBar({ type }) {
      if (Capacitor.getPlatform() === 'android') {
        const StatusBarPlugin = await import('@capacitor/status-bar');
        await StatusBarPlugin.StatusBar.setStyle({
          style: type === 'show' ? StatusBarPlugin.Style.Dark : StatusBarPlugin.Style.Light,
        });
        await StatusBarPlugin.StatusBar.setBackgroundColor({
          color: type === 'show' ? '#000000' : '#FFCC00',
        });
      }
    },
  },
}
</script>

<style lang="scss">
$header-height-sm: 300px;
$header-height-lg: 500px;

#modal-gallery {
  header.modal-header {
    border: none;
    padding-top: 2rem;

    @include media-breakpoint-up(sm) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .close {
      color: #fff;
      opacity: 1;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 20px;
      @include media-breakpoint-up(sm) {
        padding-right: 25px;
      }
    }
  }
}

.group-dropdown-wrapper {
  font-size: 12px;
  display: flex;
  align-items: center;

  svg.circle-icon {
    font-size: 4px;
  }

  #group-dropdown {
    button.btn {
      border: none;
      padding: 0 !important;
      display: flex;
      align-items: center;
      font-size: 12px !important;
      background: none;
      color: $primary;

      > span {
        border-bottom: 1px solid $primary;
      }

      > svg {
        margin-left: 0.25rem;
        font-size: 10px;
      }
    }

    .dropdown-toggle {
      box-shadow: none;
    }

    .dropdown-menu {
      li {
        &:hover {
          a {
            background-color: initial;
            cursor: initial;
          }
        }

        &.current-garden {
          background-color: $color-yellow-lighter;
          border-radius: 8px;
          margin: 0 10px;

          .dropdown-item {
            padding: 4px 9px;
          }
        }

        .dropdown-item {
          font-weight: normal;
          font-size: 12px;
          padding: 4px 19px;

          &:active {
            color: #16181b;
          }
        }
      }
    }
  }
}

.detail-hero-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff; //$color-grey-main;
  padding: 2rem;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: $header-height-sm;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
      position: sticky !important;
      height: $header-height-lg;
      top: 175 - $header-height-lg;
      width: 100%;
      z-index: 2;

      &#group-dropdown {
        position: initial !important;
      }
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1rem;
  }

  .hero-header {
    display: flex;
    align-items: center;
    z-index: 1;

    .round-icon-button {
      color: #000;
      border-color: white;
      background: white;
      width: 38px;
      height: 38px;
    }

    .name-small {
      display: none;
      margin-left: 0.634rem;
      color: #fff; //$color-grey-dark;
    }
  }

  .hero-footer,
  .hero-header,
  .hero-body {
    z-index: 1;
  }

  .hero-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1200px) {
      margin-top: auto;
      justify-content: flex-end;

      .footer-name {
        display: none;
      }
    }
    .footer-name {
      .garden-name {
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.2px;
        color: #fff; //$color-grey-dark;
      }
      .garden-address {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
      }
    }

    .footer-buttons {
      display: flex;
      .btn {
        &:not(:first-child) {
          margin-left: 0.653rem;
        }
      }
    }
  }
}
.gallery-modal-ios {
  padding-top: 32px !important;
}
//.gallery-hero-wrapper {
//  max-height: 35.625rem;
//  display: flex;
//  justify-content: center;
//}

//.gallery-thumbs {
//  max-width: 100%;
//  display: flex;
//  margin-top: 1rem;
//
//  .gallery-thumb {
//    max-height: 4.563rem;
//    cursor: pointer;
//
//    &:not(:first-child){
//      margin-left: 1rem;
//    }
//  }
//}
</style>
