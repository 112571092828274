<template>
  <div class="opening-times-wrapper">
    <div class="d-flex justify-content-end">
      <ngs-icon-group :tags="garden.tags"/>
    </div>
    <div v-if="!openingsData.length && !groupData.length && !byArrangementData.length">
      <span v-if="hasOpeningTypeGOP">
        For opening times and information, please phone, email or visit garden website.
      </span>
      <span v-else>
        This garden has now completed its National Garden Scheme openings for this year.
      </span>

      <span v-if="garden.garden_type_id == gardenTypeIdPublic"><br>{{garden.commercial}}</span>
    </div>
    <div class="opening-times-header-wrapper mb-4" v-if="openingsData.length">
      <open-days-accordion
        :garden="garden"
        :openings="openingsData"
      />
    </div>
    <div v-if="garden.group_parent_id && groupData.length" class="group-openings-wrapper mb-4">
      <group-openings-accordion
        :parent-garden="garden.parent_group_garden"
        :openings="groupData"
      />
    </div>
    <div v-if="byArrangementData.length" class="visit-by-arrangement-wrapper mb-4">
      <div class="mb-4" v-if="garden.additional">
        {{ garden.additional }}
      </div>
      <h3>Visit by Arrangement</h3>
      <opening-cards
        :openings="byArrangementData"
        hide-opening-times
      >
        <template v-slot:description="{ model }">
          <span v-if="model.canceled">
            <p>This garden is no longer open for By Arrangement bookings this year</p>
          </span>
          <span v-else>
            <p><span>This garden opens for By Arrangement visits</span>
              <span v-if="dateWording(model.start_date, model.end_date)">{{ dateWording(model.start_date, model.end_date) }}</span>
              <span v-if="groupSizeWording">{{ groupSizeWording }}</span>.<br>
            </p>
            <p class="mb-0">Please contact the garden owner to discuss your requirements and arrange a date
              for a group or bespoke visit.
            </p>
            <p class="mt-2 mb-0" v-if="model.by_arrangement_text">
              {{ model.by_arrangement_text }}
            </p>
          </span>
        </template>
      </opening-cards>
    </div>
  </div>
</template>

<script>

import snakeCase from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import OpenDaysAccordion from '@/components/gardenDetails/detailTabs/openings/OpenDaysAccordion';
import GroupOpeningsAccordion from '@/components/gardenDetails/detailTabs/openings/GroupOpeningsAccordion';
import OpeningCards from '@/components/gardenDetails/detailTabs/openings/OpeningCards';
import NgsIconGroup from '@/components/ngsIcons/NgsIconGroup';
import {
  GARDEN_TYPE_ID_PUBLIC,
  GARDEN_OPENING_TYPE_ID_ALSO_BY_ARRANGEMENT,
  GARDEN_OPENING_TYPE_ID_BY_ARRANGEMENT_ONLY,
  GARDEN_OPENING_TYPE_ID_EVENING,
  GARDEN_OPENING_TYPE_ID_GOP_NO_NGS_DATES,
  GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP,
  GARDEN_OPENING_TYPE_ID_STANDARD,
} from '@/constants'

export default {
  name: 'OpeningTimes',

  props: {
    garden: Object,
  },

  components: {
    OpenDaysAccordion,
    GroupOpeningsAccordion,
    OpeningCards,
    NgsIconGroup,
  },

  data() {
    return {
      gardenTypeIdPublic: GARDEN_TYPE_ID_PUBLIC,
      openingsDataTypeIds: [
        GARDEN_OPENING_TYPE_ID_STANDARD,
        GARDEN_OPENING_TYPE_ID_EVENING,
        GARDEN_OPENING_TYPE_ID_GOP_NO_NGS_DATES,
      ],
      groupDataTypeIds: [
        GARDEN_OPENING_TYPE_ID_OPENING_WITHIN_GROUP,
      ],
      byArrangementDataTypeIds: [
        GARDEN_OPENING_TYPE_ID_BY_ARRANGEMENT_ONLY,
        GARDEN_OPENING_TYPE_ID_ALSO_BY_ARRANGEMENT,
      ],
      openings: [],
      hasOpeningTypeGOP: false,
    }
  },

  computed: {
    minGroupSize() {
      return this.garden.min_group_size || 0;
    },

    maxGroupSize() {
      return this.garden.max_group_size < 99 ? this.garden.max_group_size : 0;
    },

    groupSizeWording() {
      let wording = null

      if (this.minGroupSize || this.maxGroupSize) {
        wording = ' for groups of'

        if (this.minGroupSize && this.maxGroupSize) {
          wording = `${wording} between ${this.minGroupSize} and ${this.maxGroupSize}`
        } else if (this.minGroupSize && !this.maxGroupSize) {
          wording = `${wording} ${this.minGroupSize}+`
        } else if (!this.minGroupSize && this.maxGroupSize) {
          wording = `${wording} up to ${this.maxGroupSize}`
        }
      }
      return wording
    },

    parentGardenName() {
      if (this.garden.parent_group_garden) {
        return this.garden.parent_group_garden.name;
      }

      return '';
    },

    openingsData() {
      return this.openings.filter((opening) => {
        console.log(opening.garden_opening_type_id);
        if (opening.garden_opening_type_id === GARDEN_OPENING_TYPE_ID_GOP_NO_NGS_DATES) {
          this.hasOpeningTypeGOP = true;
        }

        return this.openingsDataTypeIds.includes(opening.garden_opening_type_id);
      });
    },

    byArrangementData() {
      return this.openings.filter((opening) => this.byArrangementDataTypeIds.includes(opening.garden_opening_type_id));
    },

    groupData() {
      return this.openings.filter((opening) => this.groupDataTypeIds.includes(opening.garden_opening_type_id));
    },
  },

  methods: {
    async goToParentGarden() {
      const parentGarden = this.garden.parent_group_garden;

      await this.$store.dispatch('garden/fetchGarden', parentGarden.id);
      await this.$router.push({
        name: 'Garden',
        params: {
          gardenId: parentGarden.id,
          gardenName: snakeCase(parentGarden.name),
        },
      });
    },
    dateWording(startDate, endDate) {
      let wording = null
      if (moment(startDate).format('D M') !== '1 1') {
        if (moment(startDate).format('D') === '1') {
          wording = ` from ${moment(startDate).format('MMMM')}`
        } else {
          wording = ` from ${moment(startDate).format('D MMMM')}`
        }
      }

      if (moment(endDate).format('D M') !== '31 12') {
        if (moment(endDate).format('D') === moment(endDate).endOf('month').format('D')) {
          wording = `${wording} to ${moment(endDate).format('MMMM')}`
        } else {
          wording = `${wording} to ${moment(endDate).format('D MMMM')}`
        }
      }

      return wording
    },
  },

  created() {
    const now = this.$moment()
    this.openings = this.garden.openings.filter((opening) => {
      return this.$moment(opening.end_date).isSameOrAfter(now);
    });
  },
}
</script>

<style lang="scss">
.opening-times-wrapper {
  .opening-times-list-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1.154rem;

    .opening-times-dropdown {
      &:not(:first-child) {
        margin-top: 0.381rem;
      }
    }
  }

.group-openings-wrapper,
  .visit-by-arrangement-wrapper {
    .card {
      background-color: $color-yellow-lighter;
    }
  }
}
</style>
