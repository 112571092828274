<template>
  <div class="share-garden-wrapper">
    <h3 class="cta-text ngs-font m-0">Share this garden</h3>
    <div class="cta-buttons">
      <b-button
        class="icon-button"
        pill
        variant="outline-primary"
        :href="twitterUrl"
        target="_blank"
      >
        <b-img class="social-icon" :src="require('@/assets/images/icon_x.png')" alt="X" />
      </b-button>
      <b-button
        class="icon-button"
        pill
        variant="outline-primary"
        :href="facebookUrl"
        target="_blank"
      >
        <b-img class="social-icon" :src="require('@/assets/images/icon_fb.png')" alt="FaceBook" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { first } from 'lodash';

export default {
  name: 'ShareGarden',

  props: {
    garden: Object,
  },

  data: () => ({
    showFullDescritpion: false,
  }),

  computed: {
    currentUrl() {
      const url = `${process.env.VUE_APP_WEB_BASE_URL}${window.location.pathname}`;

      return encodeURIComponent(url);
    },
    twitterUrl() {
      return `https://twitter.com/intent/tweet?url=${this.currentUrl}`;
    },
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.currentUrl}&amp;src=sdkpreparse`;
    },
    nextOpening() {
      return first(this.garden.openings)
    },
  },

  mounted() {
    const twitterScript = document.createElement('script');
    twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');

    document.head.appendChild(twitterScript)
  },
}
</script>

<style scoped lang="scss">
.share-garden-wrapper {
  margin-top: 1.74rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 512px) {
    justify-content: space-between;
    max-width: 280px;
    margin: 1rem auto 0;

    .cta-buttons {
      margin-left: 0 !important;
    }
  }

  .cta-buttons {
    margin-left: 1.364rem;
    display: flex;

    .btn {
      color: black;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(:first-child) {
        margin-left: 0.749rem;
      }

      .social-icon {
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>
